const baseFontSize = 16;

export const fontFamilyFallback = {
  primary: 'sans-serif',
  secondary: 'serif',
};

export const remToPx = (rem: string) => parseFloat(rem) * baseFontSize;
export const remToHeight = (rem: string, fontsize: number) =>
  parseFloat(rem) * fontsize;
