import {
  FOUR_HOURS_IN_SECONDS,
  ONE_DAY_IN_SECONDS,
  ONE_YEAR_IN_SECONDS,
  cookieName,
} from './constants';
import { getCookie, setCookie } from 'cookies-next';

export interface MarketingParamsCookieValue {
  query?: string;
  url?: string;
  time?: number;
}

export const getMarketingParams: () => MarketingParamsCookieValue | null =
  () => {
    try {
      const marketingParamsCookieValue = getCookie(cookieName.MARKETING_PARAMS);
      const parsedCookie =
        typeof marketingParamsCookieValue === 'string'
          ? JSON.parse(marketingParamsCookieValue)
          : null;
      return parsedCookie;
    } catch (error) {
      return null;
    }
  };

export const setMarketingParams = () => {
  const marketingParamsCookieValue: MarketingParamsCookieValue = {
    query: window.location.search,
    url: window.location.href.split('?')[0],
    time: Date.now(),
  };

  setCookie(cookieName.MARKETING_PARAMS, marketingParamsCookieValue, {
    maxAge: ONE_DAY_IN_SECONDS,
    domain: '.thrivent.com',
  });
};

export const getLicensePlate: () => string | null = () => {
  let licensePlateCookieValue = getCookie(cookieName.LICENSE_PLATE);
  if (typeof licensePlateCookieValue !== 'string') {
    licensePlateCookieValue = null;
  }
  return licensePlateCookieValue;
};

export const setLicensePlate = (
  licensePlateCookieValue: string | undefined,
): void => {
  setCookie(cookieName.LICENSE_PLATE, licensePlateCookieValue, {
    maxAge: FOUR_HOURS_IN_SECONDS,
    domain: '.thrivent.com',
  });
};

export const getDeviceId: () => string | null = () => {
  let visitorIdCookieValue = getCookie(cookieName.DEVICE_ID);
  if (typeof visitorIdCookieValue !== 'string') {
    visitorIdCookieValue = null;
  }
  return visitorIdCookieValue;
};

export const setDeviceId = (deviceIdValue: string | null): void => {
  setCookie(cookieName.DEVICE_ID, deviceIdValue, {
    maxAge: ONE_YEAR_IN_SECONDS,
    domain: '.thrivent.com',
  });
};
