'use client';

import { useInitialMarketingParams } from './useMarketingParams';
import { useEffect, useState } from 'react';
import {
  LeadFormContextData,
  getLeadFormContextData,
} from 'visitor-attributes-common';

export function useLeadFormContextData(): LeadFormContextData {
  const marketingParams = useInitialMarketingParams();
  const [leadFormContextData, setLeadFormContextData] =
    useState<LeadFormContextData>({
      utmSourceDTM: '',
      utmSourceURLDTM: '',
      referenceCodeURL: '',
      referenceCodeReferrer: '',
      landingPageQueryParams: '',
      landingPageBaseURL: '',
      visitorId: '',
    });

  useEffect(() => {
    setLeadFormContextData(
      getLeadFormContextData(
        marketingParams === null
          ? null
          : {
              query: marketingParams.query,
              baseURL: marketingParams.url,
            },
      ),
    );
  }, [marketingParams]);

  return leadFormContextData;
}
