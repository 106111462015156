/**
 * Interface for Satellite object used in Adobe Analytics.
 */
interface Satellite {
  getVisitorId?(): { getMarketingCloudVisitorID?(): string };
}

/**
 * Interface for extending the Window object to include Adobe Analytics Satellite.
 */
export interface AnalyticsWindow extends Window {
  _satellite?: Satellite;
}

/**
 * Retrieves analytics IDs from the Adobe Analytics Satellite object.
 *
 * @returns - An object containing the visitor ID.
 */
export const getAnalyticsIds = () => ({
  visitorId:
    (window as AnalyticsWindow)._satellite
      ?.getVisitorId?.()
      ?.getMarketingCloudVisitorID?.() || 'default',
});
