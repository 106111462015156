'use client';

import { useEffect, useState } from 'react';
import {
  MarketingParamsCookieValue,
  getMarketingParams,
} from 'visitor-attributes-common';

export function useInitialMarketingParams(): MarketingParamsCookieValue | null {
  const [marketingParams, setMarketingParams] =
    useState<MarketingParamsCookieValue | null>(null);

  useEffect(() => {
    const persistingMarketingParams = getMarketingParams();
    if (
      JSON.stringify(marketingParams) !==
      JSON.stringify(persistingMarketingParams)
    ) {
      setMarketingParams(persistingMarketingParams);
    }
  }, [marketingParams]);

  return marketingParams;
}
