import { getAnalyticsIds } from '../getAnalyticsIds';

const marketingTerms = [/^utm/, /^e$/, /^email$/, /^gclid$/, /^cmpid$/];

export function getMarketingParamsFromSearchParams(
  searchParams: URLSearchParams,
): Record<string, string> {
  const pageQueryParams = Object.fromEntries([...searchParams.entries()]);
  return Object.keys(pageQueryParams).reduce<Record<string, string>>(
    (acc, key) => {
      if (marketingTerms.some((term) => term.test(key))) {
        acc[key] = pageQueryParams[key];
      }
      return acc;
    },
    {},
  );
}

export interface LeadFormContextData {
  // The value of utm_source parameter
  utmSourceDTM: string;
  // The path where utm_source was grabbed from
  utmSourceURLDTM: string;
  // The full url (of the submitted page) with the query parameters
  referenceCodeURL: string;
  // document.referrer
  referenceCodeReferrer: string;
  // The query stored in the landing_page_details cookie
  landingPageQueryParams: string;
  // The url stored in the landing_page_details cookie
  landingPageBaseURL: string;
  // The visitor ID from Adobe Analytics
  visitorId: string;
}

export function getLeadFormContextData(
  landingPageDetails: {
    query?: string;
    baseURL?: string;
  } | null,
): LeadFormContextData {
  const visitorId = getAnalyticsIds().visitorId;
  // If we have url parameters on submit, they're considered "stronger" than anything from our cookie
  if (window.location.search) {
    const searchParams = new URLSearchParams(window.location.search);
    const utmSource = searchParams.get('utm_source');
    const utmSourceDTM = utmSource ? utmSource : '';
    const utmSourceURLDTM = utmSource ? window.location.pathname : '';
    const referenceCodeURL = window.location.href;
    return {
      utmSourceDTM: utmSourceDTM,
      utmSourceURLDTM: utmSourceURLDTM,
      referenceCodeURL: referenceCodeURL,
      referenceCodeReferrer: document.referrer,
      landingPageQueryParams: landingPageDetails?.query ?? '',
      landingPageBaseURL: landingPageDetails?.baseURL ?? '',
      visitorId,
    };
  }

  if (landingPageDetails) {
    const searchParams = new URLSearchParams(landingPageDetails?.query);
    const utmSource = searchParams.get('utm_source');
    const utmSourceDTM = utmSource ? utmSource : '';
    const utmSourceURLDTM = utmSource
      ? new URL(landingPageDetails?.baseURL ?? '').pathname
      : '';
    const referenceCodeURL = `${window.location.origin}${window.location.pathname}${landingPageDetails?.query}`;

    return {
      utmSourceDTM: utmSourceDTM,
      utmSourceURLDTM: utmSourceURLDTM,
      referenceCodeURL: referenceCodeURL,
      referenceCodeReferrer: document.referrer,
      landingPageQueryParams: landingPageDetails?.query ?? '',
      landingPageBaseURL: landingPageDetails?.baseURL ?? '',
      visitorId,
    };
  }

  return {
    utmSourceDTM: '',
    utmSourceURLDTM: '',
    referenceCodeURL: document.URL,
    referenceCodeReferrer: document.referrer,
    landingPageQueryParams: '',
    landingPageBaseURL: '',
    visitorId,
  };
}
