const ONE_HOUR_IN_SECONDS = 60 * 60;
export const FOUR_HOURS_IN_SECONDS = ONE_HOUR_IN_SECONDS * 4;
export const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS * 24; // 86400 seconds
export const ONE_YEAR_IN_SECONDS = ONE_DAY_IN_SECONDS * 365;

export const cookieName = {
  MARKETING_PARAMS: 'landing_page_details',
  LICENSE_PLATE: 'license_plate',
  DEVICE_ID: 'ANONYMOUS_USER_ID',
} as const;
