import { AdobeDataLayer, AdobeEvent, AdobeWindow } from './types';
import { TrackingEvent } from '@analytics/shared';

/**
 * Returns the adobeDataLayer, either by getting the existing one or creating an empty
 * array if it doesn't exist. This is necessary because the adobeDataLayer is created
 * by Adobe's code and is not part of the static codebase.
 *
 * @returns The adobeDataLayer
 */
function getAdobeDataLayer(): AdobeDataLayer {
  if ('adobeDataLayer' in window === false) {
    (window as unknown as AdobeWindow).adobeDataLayer = [];
  }

  return (window as unknown as AdobeWindow).adobeDataLayer!;
}

export const pushToAdobeDataLayer = (trackingEvent: TrackingEvent): void => {
  const adobeEvent: AdobeEvent = {
    ...trackingEvent,
    event: trackingEvent.eventType,
  };

  // We decided to make our generic event have eventType but Adobe expects 'event'
  // so we have to transfer the value from eventType to event and remove eventType
  // todo this feels inelegant could be refactored
  delete adobeEvent['eventType'];

  getAdobeDataLayer().push(adobeEvent);
};

export const reportFeatureFlagsToAdobeDataLayer = (featureFlags: {
  [key: string]: boolean | string;
}): void => {
  const adobeEvent: AdobeEvent = {
    event: 'FeatureFlag',
    web: {
      webPageDetails: {
        featureFlags,
      },
    },
  };
  getAdobeDataLayer().push(adobeEvent);
};
